import React, { useEffect, useState } from "react";
import {  EventCard, NewChampionsCard, NewContentV2, NewVideoPlayer } from "../../../MainEvents/RecommendEventVideos/styles";
import { ImageUrl } from "../../../../utils/constants";
// import moment from "moment";
import { t } from "i18next";
import PropTypes from 'prop-types';
import { ChallengeHeader, ChallengeHeadermain, SeeAllButton } from "../../../ChallengeDashboardV2/styles";
// import Thumbnail from "../../../Thumbnail";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import CustomSurveyPopUp from "../../SurveyLibrary/CustomSurveyPopUp";
import LazyImage from "../../../common/LazyImage/LazyImage";
import SkeletonLoder from "../../../common/skeletonLoder";

const ActiveSurveys = (props) => {
  const{history, activeSurveys,isActiveSurveyLoading,setActiveTab}=props;
  const [onHover, setOnHover]=useState({});
  const[isCustomSurvey, setisCustomSurvey] = useState(false);
  const[showMore, setShowMore] = useState(false);
  const [activeSurveysData,setactiveSurveysData] = useState([]);

  useEffect(()=>{
    activeSurveys.length > 3 ? setactiveSurveysData(activeSurveys.slice(0, 3)) : setactiveSurveysData(activeSurveys);
  },[])


  const onEnterMouse = (id) => {
    setOnHover(id)
  }

  const onLeaveMouse = () => {
    setOnHover(-1)
  };


  return (
    <React.Fragment>
      {isActiveSurveyLoading ? <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
        {Array.from({ length: 3 }).map((_, index) => (
          <SkeletonLoder key={index} width={"395px"} height={"180px"} style={{margin:"0px 20px 20px 0px"}}/>
        ))}
      </div> :
        <>
          <ChallengeHeadermain>
            <div>
              <ChallengeHeader color={1} style={{color:"rgba(0, 92, 135, 1)", marginBottom:"25px"}}>{t("Active Surveys")}</ChallengeHeader>
            </div>
            <div>
              {activeSurveys && activeSurveys.length > 3 ? <SeeAllButton onClick={() =>{setShowMore(!showMore); showMore ? setactiveSurveysData(activeSurveys.slice(0,3)) : setactiveSurveysData(activeSurveys)}} color={"color"} style={{color: "rgba(0, 92, 135, 0.60)",fontFamily: "Rubik",fontSize: "18px",fontWeight: 400,lineHeight: "24px"}}>{showMore ? t('See Less <') : t('See All >')}</SeeAllButton> : ''}
            </div>
          </ChallengeHeadermain>
     
          <div style={{ display: activeSurveysData.length > 0  && "flex", width: "100%", flexWrap: "wrap" }}>
            {activeSurveysData &&
                activeSurveysData.length > 0  &&
                activeSurveysData.map((survey, index) => (
       
                  <NewChampionsCard key={index} grid={0} margin={"25px"} style={{marginBottom:"25px"}}>
                    <EventCard videoCSS={0} onMouseEnter={() => onEnterMouse(survey.id)} onMouseLeave={() => onLeaveMouse()} style={{height:"auto", minHeight:"331px", marginBottom:'0px'}}>
                      <NewVideoPlayer>
                        
                        <LazyImage
                          src={`${ImageUrl}/${survey.image}`}
                          alt={survey.survey_point}
                          fallbackImage={ImageUrl + "/admin-survey/survey_backdrop.png"}
                        />
                      </NewVideoPlayer>
                      <div style={{position:"absolute", top:"20px", left:"25px", background:"white",borderRadius:"3px", /*height:"30px",*/ padding:"5px 10px",textTransform:"capitalize"}}><span style={{color: "#005C87",fontFamily: "Rubik",fontSize: "14px",fontWeight: 400,lineHeight: "20px"}}>{survey.status}</span></div>
                      {onHover === survey.id &&
                  <div className='educateImageBackground'>
                    <div className="getStarted" style={{background:'white',color:'#005C87'}} onClick={() =>
                      history.push(`/company/survey-analytics/${survey.id}`)
                    }>{t("View Insight")}</div>
                  </div>
                  
                      }
                      <NewContentV2 newPadding={'newPadding'} style={{minHeight:'auto',flexDirection:"column"}}>
                        <div style={{display:"flex",justifyContent:"space-between", alignItems:"center"}}>
                          <div title={survey?.title} style={{textOverflow: "ellipsis",whiteSpace: "nowrap",overflow: "hidden", color:"rgba(0, 92, 135, 1)",fontFamily:"Rubik-Medium", fontSize:"18px",lineHeight:"24px", width:"72%"}}>{survey?.title}</div>
                          <div style={{color:"rgba(0, 92, 135, 0.6)", fontFamily: "Rubik-Regular",lineHeight:'20px',fontSize:'16px',display:'flex',flexDirection:'column', flexWrap:'wrap', alignItems:'end'}}>{survey?.survey_questions_count} Questions</div>
                        </div>
                        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                          <div style={{color: "rgba(0, 92, 135, 0.60)",fontFamily: "Rubik-Regular",fontSize: "16px",fontWeight: 400,lineHeight: "20px", marginTop:'7px'}}>{survey?.category_name}</div>
                          <div style={{color: "rgba(0, 92, 135, 0.60)",fontFamily: "Rubik-Regular",fontSize: "16px",fontWeight: 400,lineHeight: "20px", marginTop:'7px'}}>{Math.round(survey?.survey_completion_percentage) || 0}% Completed</div>
                        </div>
                      </NewContentV2>
                    </EventCard>
                  </NewChampionsCard>)) }      
            {activeSurveys.length < 3 ?  <NewChampionsCard grid={0} margin={"16px"} >
              <EventCard videoCSS={0} style={{background:'transparent',border:'3px dashed rgba(0, 92, 135, 0.30)',borderRadius:"6px",height:'331px', boxShadow:'none'}}>
                <div style={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}} onClick={() =>
                  setisCustomSurvey(true)
                }>
                  <Button style={{background:'#005C87',borderRadius:'3px',color:'white',border:"none"}}>{t("+ New Custom Survey")}</Button>
                </div>
              </EventCard>
            </NewChampionsCard>:null}
            {isCustomSurvey && <CustomSurveyPopUp showModal={isCustomSurvey} onClose={()=>setisCustomSurvey(false)} setActiveTab={setActiveTab}/>}
            
            
          </div>
        </>}
    </React.Fragment>
  );
};

ActiveSurveys.propTypes = {
  history: PropTypes.object,
  surveys:PropTypes.array.isRequired,
  activeSurveys:PropTypes.array,
  isActiveSurveyLoading:PropTypes.bool,
  setActiveTab:PropTypes.func
};

export default withRouter(ActiveSurveys);
