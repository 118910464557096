import styled from "styled-components";

const NoActiveSurveyCard = styled.div`
width: 1246px;
height: 120px;
border-radius: 6px;
background: linear-gradient(133deg, #AF87C1 25.62%, #6781C3 73.49%);
margin-bottom: 20px;
display:flex;
    align-items:center;
.content{
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%
}

.left-side{
    display:flex;
    align-items:center;

    .text{
color:  #FFF;
font-family: Rubik-Regular;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 24px; 

    }
    .name{
        color:  #FFF;
font-family: Rubik-Medium;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 24px; 

    }

}

.right-side{
    margin-right:75px;
    display:flex;
    align-items:center;
    .btn{
        border-radius: 3px;
background: var(--White, #FFF);

box-shadow: 1px 4px 10px 0px rgba(0, 92, 135, 0.40);
width: 299px;
height: 50px;
flex-shrink: 0;
border:none;
display:flex;
align-items:center;
justify-content:center;

.text{
    color:  #005C87;
${'' /* text-align: right; */}
font-family: Rubik-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px;
margin-left:10px;
}

    }
}`;

export { NoActiveSurveyCard };
