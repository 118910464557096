import styled, { css } from 'styled-components';
import { CardContainer } from '../../../HealthTabV2/styles';
const BoldFont = 'Rubik-Medium';
// const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const CardDashboard = styled.div`
  float: left;
  ${'' /* background-color: #ededed; */}
  width: 100%;
  display: block;
  @media (max-width: 1275px) and (min-width: 501px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: 620px) {
    padding: 0 10px 20px;
  }
`;

const CardContainerV2 = styled(CardContainer)`
  margin-right: 26px;
  margin-bottom: 26px;
  background: #fff;
  padding: 15px;
  border-radius: 6px;
  width:292px;
  &:nth-child(4n){
    margin-right: 0;
  }
  cursor: pointer;
  ${({ active }) => active === 1 && css`
    ${'' /* background-color: #1E76AB; */}
    ${'' /* color: #FFFFFF; */}
    > p {
      ${'' /* color: #FFFFFF; */}
      ${'' /* opacity: 0.5; */}
    }
  `}

  .status{
    margin-top:31px;
    background:rgba(244, 170, 169, 0.5);
    border-radius:3px;
    padding:7px;
    color: #005C87;
text-align: center;
font-family: Rubik;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
min-width:62px;
  }
`;

const Row1 = styled.div`
display:flex;
align-items:center;
.icon{
    border-radius: 6px;
${'' /* opacity: 0.1; */}
background:  rgba(0, 92, 135, 0.1);
width: 50px;
height: 50px;
display:flex;
align-items:center;
justify-content:center;
}
.category{
    margin-left:16px;
    color: #005C87;
font-family:"Rubik-Medium";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px;
display:flex;
align-items:center;

}
  ${'' /* float: left; */}
  width: 100%
  ${'' /* padding: 10px 0 0 0; */}
  ${'' /* min-height: 67px; */}
  
  & > p {
    font-size: 16px;
    ${'' /* max-width: 195px; */}
    float: left;
    margin: 0;
    text-transform: uppercase;
    color:#005C87;
    ${'' /* color: ${({ active }) => active ? '#FFFFFF' : '#6F767E'}; */}
    padding: 13px 0 0 7px;
    line-height: 20px;
    font-family: ${BoldFont};
    font-weight: 700;
    @media (max-width: 1275px) {
     max-width: none;
    }
  }
  
  & > i {
    float: right;
    font-size: 17px;
    color: #999999;
  }
  
  img {
    width: 75px;
    height: 50px;
    float: right;
  }
`;

const Row2 = styled.div`
  ${'' /* float: left; */}
  width: 100%;
  padding: 0px;
  display:flex;
  align-items:center;
  padding-top:15px;
  & > p {
    font-size: 12px;
    max-width: 205px;
    float: left;
    margin: 0;
    text-transform: uppercase;
    color:#005C87;
    ${'' /* color: ${({ active }) => active ? '#FFFFFF' : '#6F767E'}; */}
    padding: 13px 0 0 7px;
    line-height: 17px;
    font-family: ${BoldFont};
    font-weight: 700;
    @media (max-width: 1275px) {
     max-width: none;
    }
  }
  
  & > i {
    float: right;
    font-size: 17px;
    color: #999999;
  }

  .percentage{
    color: #005C87;
font-family: Rubik-Medium;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 100% */
  }

  .score{
    color: rgba(0, 92, 135, 0.60);
font-family:Rubik-Regular;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
margin-left:8px;
  }
  
  img {
    width: ${({ circularImage }) => circularImage ? '50px' : '75px'};
    height: 50px;
    float: right;
    ${'' /* filter: ${({active}) => active ? 'invert(100%) sepia(79%) saturate(60%) hue-rotate(285deg) brightness(189%) contrast(246%)' : 'none'} */}
  }
`;

const Row3 = styled.div`
  float: left;
  width: 100%
  display: block;
  padding: 10px 0;
  
  & > div {
    font-size: 18px;
    float: left;
    margin: 0;
    color: rgba(40, 44, 55, 0.5);
    padding: 0 0 0 7px;
    letter-spacing: 1px;
    font-family: ${MediumFont};
    & > span:nth-child(2) {
     padding: 0 0 0 7px;
    }
  }
  & > div:nth-child(2) {
    float: right;
    color: rgba(40, 44, 55, 1);
    font-family: ${BoldFont}
  }
  
  img {
    width: 27px;
    height: 25px;
    float: left;
  }
`;

export {CardDashboard,CardContainerV2,Row1,Row2,Row3}