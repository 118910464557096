import React, { useEffect } from "react";
import { NoActiveSurveyCard } from "./style";
import LazyImage from "../../../common/LazyImage/LazyImage";
import { ImageUrl } from "../../../../utils/constants";
import { launchCalendarIcon } from "../../../../utils/icons";
import PropTypes from 'prop-types';

const NoActiveSurvey = ({name, role, setisCustomSurvey}) => {
  useEffect(() => {}, [name]);
  return (
    <React.Fragment>
      <NoActiveSurveyCard>
        <div className="content">
          <div className="left-side">
            <LazyImage
              src={ImageUrl + "/admin-survey/no_survey_graphics.png"}
              style={{ width: "111px", marginLeft:"25px" }}
            />
            <div style={{width:"544px", marginLeft:"24px"}}>
              
                
              {role === "ADMIN" ? (<span className="text"><span className="name">{name}</span>, doesn’t have any Active Survey. Launch a company Survey today for <span className="name">{name}!</span> </span>) : (<span className="text">Hey <span className="name">{name}</span>, Your Company doesn’t have any Active Survey. Launch a company Survey today for your Company! </span>)}
                
              
            </div>
          </div>
          <div className="right-side">
            <div className="btn" onClick={()=>setisCustomSurvey(true)}>{launchCalendarIcon()}<span className="text">Launch a survey</span></div>
          </div>
        </div>
      </NoActiveSurveyCard>
    </React.Fragment>
  );
};

NoActiveSurvey.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string.isRequired,
  setisCustomSurvey:PropTypes.func,
}

export default NoActiveSurvey;
